<template>
  <div class="login-container">
    <div v-if="pageLoader" class="page-loader">
      <div class="d-flex flex-column" style="margin: 0 auto;">
        <img src="../assets/img/ozo_login.svg" alt="Loading...">
        <div class="loader">
          <div class="progress"></div>
        </div>
      </div>
    </div>
    <!-- <v-container id="login" class="login-background"> -->
    <v-row justify="center" class="mt-10">
      <v-col md="4">
        <div class="top">
          <!-- <div class="transparent-card"> -->
          <div class="top-image-container">
            <img class="top-image" src="../assets/img/ozo_login.svg" alt="Logo" />
          </div>
          <v-card :loading="loading">
            <v-card-title class="mt-1 d-flex flex-column align-center">Логин</v-card-title>
            <form class="mx-10 login-form" @submit="handleSubmit">
              <div class="phone">
                <label>Введите логин</label>
                <br>
                <input type="text" v-model="username" :disabled="sent" @keydown.enter="startAuthentication"
                  placeholder="Введите логин" class="login_input">
              </div>
              <div class="phone">
                <label>Введите пароль</label>
                <br>
                <input type="password" v-model="password" :disabled="sent" @keydown.enter="startAuthentication"
                  placeholder="********" class="login_input">
              </div>
              <button id="sign-in-code" type="submit">
                Войти
              </button>
              <p v-if="loginError" style="color: red; text-align: center; margin-top: 15px ;">Неправильный логин или
                пароль</p>
            </form>
            <!-- <div class="d-flex flex-column align-center">
              <a v-if="sent"
                :class="['my-2', 'text-body-2', 'font-weight-regular', 'no-underline', { 'inactive-link': resendTimer > 0 }]"
                href="#" @click.prevent="resendSMS" :style="{ pointerEvents: resendTimer > 0 ? 'none' : 'auto' }"
                style="color:#0A7AFF">
                Повторно отправить SMS
                <span v-if="resendTimer > 0"> ({{ resendTimer }} сек)</span>
              </a>
              <a v-if="sent" class="my-2 text-body-2 none" href="" @click.prevent="resetPhoneNumber"
                style="color:#0A7AFF">
                Изменить номер
              </a>
            </div>
            <v-col cols="12">
              <div class="d-flex flex-column align-center text-center">
                <button type="button" id="sign-in-button" v-if="!sent" @click="startAuthentication()">
                  Получить код
                </button>
                <button id="sign-in-code" type="submit">
                  Войти
                </button>
              </div>
            </v-col> -->
            <v-col cols="12 text-center">
              <!-- <v-btn @click="signInWithGoogle" class="google-button py-7 px-4" large v-if="!sent">
                    <span class="mdi mdi-google"></span>Войти с учетной записью Google
                  </v-btn> -->
              <!-- <button v-if="!sent" @click="signInWithGoogle" type="button" class="login-with-google-btn">
                Войти с учетной записью Google
              </button> -->
            </v-col>
          </v-card>
        </div>
        <!-- </div>       -->
      </v-col>
    </v-row>
    <!-- </v-container> -->
  </div>
</template>

<style>
/* .transparent-card {
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.00) 100%);
  backdrop-filter: blur(13px);
  border-radius: 50px;
  padding: 215px 280px 111px; Добавьте отступ для контента
  padding: 200px;
  top: 0;
  left: 0;
  max-width: 1296px;
  height: 100%;
  z-index: 1;
  overflow: hidden; 
} */
.login-container {
  display: flex;
  align-items: center;
  /* Выравнивание по центру по вертикали */
  justify-content: center;
  /* Выравнивание по центру по горизонтали */
  height: 100vh;
  /* Контейнер занимает 100% высоты окна браузера */
  background-image: url("../assets/img/ozo_back.png");
  /* Фоновое изображение */
  background-size: cover;
  background-position: center;
  /* overflow: hidden;  */
}

.top-image-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 120px;
  height: 100px;
  z-index: 1;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); */
}

.top-image {
  width: 100%;
  height: 100%;
  margin-bottom: 55px;
}

.phone {
  margin-bottom: 0;
}

.phone>label {
  font-size: 1.1rem;
  margin-left: 0.6em !important;
}

.sms-code>label {
  font-size: 1.1rem;
  margin-left: 0.6em !important;
}

::v-deep .v-card {
  padding: 24px;
}

.login-form {
  /* padding: 24px; */
  margin: 0 40px;
  display: flex;
  flex-direction: column;

}

.login-form :first-child {
  margin: 1px 0 15px 0;
}

.login-form :last-child {
  margin: 1px 0 10px 0;
}

.login_input {
  width: 100%;
  padding: 14px;
  outline: none;
  border-radius: 5px;
  border: 0.5px solid var(--unnamed, #5E5E5E);
  background: #FFF;
  box-sizing: border-box;
  border-radius: 5px;
  color: #333333;
  font-size: 16px;
}

.login-with-google-btn {
  transition: background-color .3s, box-shadow .3s;

  padding: 12px 16px 12px 54px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);

  color: #333;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  border-radius: 50px;

  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: #F2F2F6;
  background-repeat: no-repeat;
  background-size: 34px 34px;
  background-position: 15px 7px;
  margin-bottom: 54px;

  &:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
  }

  &:active {
    background-color: #eeeeee;
  }

  &:focus {
    outline: none;
    box-shadow:
      0 -1px 0 rgba(0, 0, 0, .04),
      0 2px 4px rgba(0, 0, 0, .25),
      0 0 0 3px #c8dafc;
  }

  &:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    cursor: not-allowed;
  }
}

.inactive-link {
  color: gray !important;
  /* Примените желаемый стиль для неактивной ссылки */
  cursor: not-allowed;
}

.text-center {
  text-align: center !important;
}

.google-button {
  border-radius: 50px;
  background: var(--background, #F2F2F6)
}

::v-deep .google-button:hover {
  background-color: rgb(52, 75, 88);
}

#sign-in-button {
  border-radius: 8px;
  background-color: #333;
  color: var(--white, #FFF);
  padding: 14px 56px;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

#sign-in-code {
  border-radius: 8px;
  padding: 14px 56px;
  width: 160px;
  background-color: #333;
  color: var(--white, #FFF);
  text-align: center;
  width: 200px;
  margin: 20px auto;
  /* H3 - 16 SemiBold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  margin: 10px auto 0;
}

::v-deep .v-application a {
  color: #0A7AFF
}
</style>


<script>
import {
  getAuth,
  signInWithPhoneNumber,
  RecaptchaVerifier,
  GoogleAuthProvider,
  signInWithPopup,
  // signOut
} from "firebase/auth";
// import firebase from 'firebase/app';
import { mapMutations } from "vuex";
import { mask } from 'vue-the-mask';
export default {
  name: "Login",
  directives: { mask },
  created() {
    if (this.$store.state.user.logged == true) {
      // this.$router.push({ path: "/Analytics" });
    }
    if (this.$route.query.reset) {
      // Вызываем метод из стора для сброса состояния пользователя
    }
  },
  data: () => ({
    recaptchaInitialized: false,
    sent: false,
    phone: null,
    code: "",
    loading: false,
    error: null,
    resendDisabled: false,
    resendTimer: 0,
    resendInterval: null,
    pageLoader: false,
    loginError: false,
    username: '',
    password: ''
  }),
  methods: {
    ...mapMutations(["setToken", "setData", "setLogged", "setRoles", "setCurrentRes"]), // Импортируем и используем мутации из стора
    emptyUser() {
      this.setToken(null);
      this.setData({});
      this.setLogged(false);
      this.setRoles([]);
      this.setCurrentRest(null);
    },
    handleSubmit(event) {
      this.pageLoader = true
      event.preventDefault()
      this.$http
        .post("api/Auth/Login", {
          headers: {
            "Content-Type": "application/json",
          },
          name: this.username,
          password: this.password,
          withCredentials: true
        }).then((response) => {
          console.log(response.data);
          this.$store.commit("setData", response.data)
          this.checkWhoAmI()
        }).catch(error => {
          setTimeout(() => {
            this.pageLoader = false

          }, 1500);
          this.loginError = true
          this.password = ""
        })
    },
    async checkWhoAmI() {
      await this.$http
        .get("api/Auth/WhoAmI", {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true // Включение отправки cookie
        }).then((response) => {
          console.log(response.data);
          this.$store.commit("setRoles", response.data)
          const delayMilliseconds = 1000;
          if (response.data.length >= 1) {
            this.$store.commit("setLogged", true)
            this.$router.push({ path: "/Analytics" }, delayMilliseconds);
          } else {
            this.$store.commit("setLogged", false)
            this.$router.push({ path: "/error" }, delayMilliseconds);
          }
        }).catch((error) => {
          console.log(error)
          this.loginError = true;

          this.pageLoader = false

        }
        )
    },

    async signInWithGoogle() {
      var auth = getAuth();
      const provider = new GoogleAuthProvider();
      this.loading = true;
      this.pageLoader = true;
      try {
        const result = await signInWithPopup(auth, provider);
        console.log('Аутентификация через Google успешна!');
        const idToken = await auth.currentUser.getIdToken(true);
        this.initUser(result.user);
        console.log('idToken:', idToken);
        this.loading = false;
      } catch (error) {
        console.error('Ошибка при аутентификации через Google:', error);
      }
    },
    googleSignout() {
      // firebase.auth().signOut()
      //   .then(() => {
      //     console.log('Signout Succesfull');
      //   })
      //   .catch(error => {
      //     console.log('Signout Failed:', error);
      //   });
    },
    // changeNumber() {
    //   try {
    //     this.resetAuthenticationState();
    //     this.initializeAuthentication();
    //   } catch (error) {
    //     console.error("Error in changeNumber:", error);
    //   }
    //   },
    //   resetAuthenticationState() {
    //     this.sent = false;
    //     this.phone = "";
    //     this.loading = false;
    //     clearInterval(this.resendInterval);
    //     this.resendTimer = 0;
    //     if (window.recaptchaVerifier) {
    //       window.recaptchaVerifier.clear();
    //     }
    //     window.confirmationResult = null;
    //   },
    //   initializeAuthentication() {
    //     var auth = getAuth();
    //     window.recaptchaVerifier = new RecaptchaVerifier(
    //       "get-code-button",
    //       {
    //         size: "invisible",
    //         callback: () => {},
    //       },
    //       auth
    //     );
    //     this.sendPhone(auth)
    //   },
    startAuthentication() {
      var auth = getAuth();
      if (!this.recaptchaInitialized) {
        window.recaptchaVerifier = new RecaptchaVerifier(
          "sign-in-button",
          {
            size: "invisible",
            callback: () => { },
          },
          auth
        );
        this.recaptchaInitialized = true;
      }
      this.sendPhone(auth);
    },
    startResendTimer() {
      this.resendTimer = 60; // Установите начальное значение таймера (в секундах)
      clearInterval(this.resendInterval);
      this.resendInterval = setInterval(() => {
        if (this.resendTimer > 0) {
          this.resendTimer--;
        } else {
          clearInterval(this.resendInterval);
        }
      }, 1000); // Обновление таймера каждую секунду
    },

    resendSMS() {
      this.startAuthentication(); // Это повторно отправляет смс
      this.startResendTimer(); // Таймер
    },
    sendPhone(auth) {
      var appVerifier = window.recaptchaVerifier;
      this.loading = true;
      console.log(auth);
      console.log("Loading::" + this.loading);
      console.log("Телефон:" + this.phone);
      console.log(appVerifier);
      signInWithPhoneNumber(auth, this.phone, appVerifier)
        .then(async (confirmationResult) => {
          window.confirmationResult = confirmationResult;
          console.log(confirmationResult);
          this.loading = false;
          this.sent = true;
          //const verificationId = confirmationResult.verificationId;
          const idToken = await auth.currentUser.getIdToken(true); // true здесь означает обновление токена
          console.log("idToken:", idToken);
        })
        .catch((error) => {
          console.log(error);
          this.error = error;
          this.loading = false; // Обязательно сбросьте loading в случае ошибки
        });
    },
    sendCode() {
      this.loading = true;
      this.pageLoader = true;
      window.confirmationResult
        .confirm(this.code)
        .then((response) => {
          this.loading = false;
          this.initUser(response);
        })
        .catch((error) => {
          this.flag = true;
          this.loading = false;
          this.error = error;
        });
    },
    resetPhoneNumber() {
      this.sent = false;
      this.phone = null;
      this.code = ''; // Сброс кода, если это также необходимо
      this.recaptchaVerifier = null;
    },
    async initUser(data) {
      try {
        await this.$store.dispatch("fetchUser", data.user);
        // Выполняем редирект только при успешном завершении fetchUser
        // const delayMilliseconds = 1500;
        // setTimeout(() => {
        //   this.$router.push({ path: "/RestaurantChoose" }); // Перенаправляем после задержки
        // }, delayMilliseconds);
        setTimeout(() => {
          this.pageLoader = false;
        }, 2000)
        // this.$router.push({ path: "/admin/manufacturer" },delayMilliseconds);
      } catch (error) {
        console.error("Ошибка при получении пользователя::", error);
        // Обработка ошибки, например, отображение сообщения пользователю
      }
    },

  },
  watch: {
    sent(newSentStatus) {
      if (newSentStatus) {
        this.startResendTimer();
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.resendInterval);
  },
};
</script>
